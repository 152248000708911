import React from 'react'
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import FormSection from '../../components/page-parts/form-section';
import PolicyBlock from '../../components/policy-block';


const PolicyPage = () => {

  const pageTitle = 'Политика конфиденциальности'
  // const pageDescription = 'Предоставляем различные услуги в сфере веб-разработки. Разработка сайтов (лендингов, корпоративных, квизов, интернет-магазинов), веб-дизайн, маркетинг и реклама, интеграция с сервисами автоматизации бизнеса.'
  // const keywords = ''

  return (
    <Layout fixedHeader={false}>
      <Seo title={pageTitle} />
      <PolicyBlock />
      <FormSection />
    </Layout>
  )
}

export default PolicyPage